import { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";

const PinBox = forwardRef(({ digits, displayCode, margin, setPin, isMobile }, ref) => {
  const [list, setList] = useState([]);
  const [code, setCode] = useState("");
  const [hasFocus, setHasFocus] = useState(true);

  const controlRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        setHasFocus(true);
        controlRef.current.focus();
      });
    },
  }));

  useEffect(() => {
    function handleClickOutside(event) {
      if (hasFocus && controlRef.current && !controlRef.current.contains(event.target)) {
        setHasFocus(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    let tempList = [];
    for (var i = 0; i < digits; i++) tempList[i] = { index: i, digit: "" };
    setList(tempList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keyDown = (e) => {
    switch (e.key) {
      case "Backspace":
      case "Delete":
        e.preventDefault();
        if (code.length > 0) {
          let tempList = [...list];
          tempList[code.length - 1].digit = "";
          setList(tempList);
          let temp = code.substring(0, code.length - 1);
          setCode(temp);
          setPin(temp);
        }
        break;
      case "0":
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
      case "7":
      case "8":
      case "9":
        e.preventDefault();
        if (code.length < digits) {
          let temp = `${code}${e.key}`;
          setCode(temp);
          setPin(temp);
          let tempList = [...list];
          tempList[code.length].digit = e.key;
          setList(tempList);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div tabIndex="1" className="pin-box" style={{ margin: margin && margin, position: "relative" }}>
      {list.length > 0 &&
        list.map((item) => {
          return (
            <div key={item.index} className="pin-digit" style={{ background: !hasFocus ? "#bbdaee" : code.length === 0 ? (item.index === 0 ? "#659cd5" : "#bbdaee") : code.length === digits ? (item.index === code.length - 1 ? "#659cd5" : "#bbdaee") : item.index === code.length ? "#659cd5" : "#bbdaee" }}>
              <p>{item.digit !== "" ? (displayCode ? item.digit : "●") : item.digit}</p>
            </div>
          );
        })}
      <div style={{ position: "absolute", height: "100%", width: "100%", background: "transparent", zIndex: 150 }}>
        <input ref={controlRef} type="number" style={{ cursor: "none", caretColor: "white", height: "100%", width: "100%", background: "transparent", outline: "none", border: "none", color: "white" }} onFocus={() => setHasFocus(true)} onBlur={() => setHasFocus(false)} onKeyDown={keyDown} />
      </div>
    </div>
  );
});

export default PinBox;
