import React from "react";

import * as icons from "../../library/icons";

const Wizard = ({ step, wizardList, margin, message }) => {
  return (
    <>
      <div className="wizard" style={{ margin: margin && margin }}>
        {wizardList.map((item) => {
          return (
            <React.Fragment key={item.step}>
              <div className="wizard-item" style={{ background: item.step <= step && "var(--success)", border: item.step <= step && "1px solid var(--success)" }}>
                <svg viewBox="0 0 24 24">
                  <path d={icons[item.icon]} style={{ fill: item.step <= step && "var(--white)" }}></path>
                </svg>
              </div>
              {item.step !== wizardList.length - 1 && <span className="wizard-divider" style={{ borderBottom: item.step < step && "1px solid var(--success)" }}></span>}
            </React.Fragment>
          );
        })}
      </div>
      {message && <p className="validation-message">{message}</p>}
    </>
  );
};

export default Wizard;
