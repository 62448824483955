import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useMain, useMessage } from "../../context/MainContext";

import { Wizard, TextBox, Button, TabLockPanel, TextAreaBox, CheckBox, RadioButton, PinBox } from "../../controls";
import { titleCase, generateCode } from "../../library/heper";

import axios from "../../library/service";

let verificationCode = "";

const ApplicationPage = () => {
  const { setIsBusy } = useMain();
  const [step, setStep] = useState(0);
  const [message, setMessage] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [mobile, setMobile] = useState("");
  const [stallId, setStallId] = useState(0);
  const [description, setDescription] = useState("");
  const [accept, setAccept] = useState(false);
  const [code, setCode] = useState("");

  const { messageBox } = useMessage();

  const navigate = useNavigate();

  const wizardList = [
    { step: 0, icon: "person" },
    { step: 1, icon: "shop" },
    { step: 2, icon: "bank" },
    { step: 3, icon: "verify" },
  ];

  const stallList = [
    { stallId: 1, description: "Beverage Stall - N$2000" },
    { stallId: 2, description: "Food Stall - N$1500" },
    { stallId: 3, description: "Hubbly Stall - N$800" },
    { stallId: 4, description: "Ice Stall - N$500" },
    { stallId: 5, description: "Wood Stall - N$500" },
  ];

  const businessNameRef = useRef();
  const contactPersonRef = useRef();
  const nationalIdRef = useRef();
  const mobileRef = useRef();
  const descriptionRef = useRef();
  const pinRef = useRef();

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 616px)"); // Adjust the breakpoint as needed

    const handleMobileChange = (event) => {
      setIsMobile(event.matches);
    };

    setIsMobile(mobileMediaQuery.matches);

    mobileMediaQuery.addEventListener("change", handleMobileChange);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleMobileChange);
    };
  }, []);

  useEffect(() => {
    setMessage("");
  }, [businessName, contactPerson, nationalId, mobile, stallId, accept]);

  // useEffect(() => {
  //   if (step === 0) {
  //     businessNameRef.current.focus();
  //   } else if (step === 3) {
  //     pinRef.current.focus();
  //   }
  // }, [step]);

  const validateBusiness = () => {
    if (!businessName.trim()) {
      setMessage("Enter business name");
      businessNameRef.current.focus();
    } else if (!contactPerson.trim()) {
      setMessage("Enter name of contact person");
      contactPersonRef.current.focus();
    } else if (!nationalId.trim()) {
      setMessage("Enter ID number");
      nationalIdRef.current.focus();
    } else if (nationalId.trim().length !== 11) {
      setMessage("Invalid ID number");
      nationalIdRef.current.focus();
    } else if (!/^\d+$/.test(nationalId.trim())) {
      setMessage("Invalid ID numberaa");
      nationalIdRef.current.focus();
    } else if (!mobile.trim()) {
      setMessage("Enter mobile number");
      mobileRef.current.focus();
    } else if (!/^\d+$/.test(mobile.trim())) {
      setMessage("Invalid mobile number");
      mobileRef.current.focus();
    } else if (mobile.trim().length !== 10) {
      setMessage("Invalid mobile number");
      mobileRef.current.focus();
    } else if (mobile.trim().substring(0, 3) !== "081" && mobile.trim().substring(0, 3) !== "085") {
      setMessage("Invalid mobile number");
      mobileRef.current.focus();
    } else {
      setStep(1);
    }
  };

  const validateStall = () => {
    if (stallId < 1) {
      setMessage("Choose a stall you are applying for");
    } else if (!description.trim()) {
      setMessage("Enter description of your activity");
      descriptionRef.current.focus();
    } else {
      setStep(2);
    }
  };

  const validateTerms = () => {
    if (!accept) {
      setMessage("Please accept Ts&Cs to continue");
    } else {
      sendCode();
    }
  };

  const sendCode = async () => {
    setIsBusy(true);

    verificationCode = generateCode(5);

    const parameter = {
      mobile: mobile.trim(),
      verificationCode,
      typeId: 3,
    };

    try {
      await axios.post("verify", JSON.stringify(parameter)).then(async (response) => {
        setIsBusy(false);

        let result = response.data;

        if (result.type === 1) {
          setStep(3);
        } else {
          setMessage(result.message);
        }
      });
    } catch (error) {
      setIsBusy(false);
      setMessage(error.message);
    }
  };

  const submit = async () => {
    if (verificationCode !== code) {
      messageBox({ type: 2, message: "Invalid verification code" });
    } else {
      setIsBusy(true);

      const parameter = {
        eventId: 1,
        businessName: businessName.trim().replaceAll("'", ""),
        contactPerson: titleCase(contactPerson.trim().replaceAll("'", "")),
        nationalId: nationalId.trim().replaceAll("'", ""),
        mobile: mobile.trim().replaceAll("'", ""),
        description: description.trim().replaceAll("'", ""),
        applicationFee: 50,
        stallId: stallId,
        stall: stallId === 1 ? "Beverage" : stallId === 2 ? "Food" : stallId === 3 ? "Hubbly" : stallId === 4 ? "Ice" : "Wood",
        stallFee: stallId === 1 ? 2000 : stallId === 2 ? 1500 : stallId === 3 ? 800 : 500,
      };

      try {
        await axios.post("submit", JSON.stringify(parameter)).then(async (response) => {
          setIsBusy(false);

          let result = response.data;

          if (result.type === 1) {
            messageBox({ type: result.type, message: result.message }).then(() => {
              navigate("/home");
            });
          } else {
            setMessage(result.message);
          }
        });
      } catch (error) {
        setIsBusy(false);
        setMessage(error.message);
      }
    }
  };

  if (isMobile) {
    return (
      <div>
        <Wizard step={step} wizardList={wizardList} margin="30px" message={message} />

        {step === 0 && (
          <TabLockPanel direction="column" margin="20px 30px">
            <TextBox ref={businessNameRef} type="text" icon="shop" label="Business name" text={businessName} setText={setBusinessName} margin="0 0 30px 0" />
            <TextBox ref={contactPersonRef} type="text" icon="person" label="Contact person" text={contactPerson} setText={setContactPerson} margin="0 0 30px 0" />
            <TextBox ref={nationalIdRef} type="number" icon="id" label="ID number" text={nationalId} setText={setNationalId} margin="0 0 30px 0" />
            <TextBox ref={mobileRef} type="tel" icon="mobile" label="Mobile e.g 081xxxxxxx" text={mobile} setText={setMobile} margin="0 0 30px 0" />
            <Button icon="apply" caption="CONTINUE" width="150px" click={validateBusiness} />
          </TabLockPanel>
        )}
        {step === 1 && (
          <TabLockPanel direction="column" margin="20px 30px">
            <RadioButton source={stallList} displayField="description" selectionField="stallId" value={stallId} setValue={setStallId} margin="20px" />
            <TextAreaBox ref={descriptionRef} icon="shop" label="Description, e.g braai meat, cool drink etc" text={description} setText={setDescription} margin="0 0 30px 0" />
            <div style={{ display: "flex", alignContent: "space-between" }}>
              <Button icon="back" caption="BACK" width="150px" click={() => setStep(0)} />
              <Button icon="apply" caption="CONTINUE" width="150px" click={validateStall} />
            </div>
          </TabLockPanel>
        )}
        {step === 2 && (
          <TabLockPanel direction="column" margin="20px 30px">
            <h1 style={{ margin: "0 auto 10px auto", color: "var(--error)" }}>BANKING DETAILS:</h1>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ margin: "10px 20px" }}>Bank:</p>
              <p style={{ margin: "10px 20px", fontWeight: 500, color: "var(--primary)" }}>First National Bank</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ margin: "10px 20px" }}>Account name:</p>
              <p style={{ margin: "10px 20px", fontWeight: 500, color: "var(--primary)" }}>PstMobile</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ margin: "10px 20px" }}>Account number:</p>
              <p style={{ margin: "10px 20px", fontWeight: 500, color: "var(--primary)" }}>64279837884</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ margin: "10px 20px" }}>Reference:</p>
              <p style={{ margin: "10px 20px", fontWeight: 500, color: "var(--primary)", textAlign: "right" }}>USE BUSINESS NAME AS REFERENCE</p>
            </div>
            <CheckBox caption="I accept terms and conditions (Application fee N$50.00)" value={accept} setValue={setAccept} position="right" />
            <h1 style={{ textAlign: "center", color: "var(--error)", marginTop: "10px" }}>PLEASE NOTE!!!</h1>
            <p style={{ color: "var(--dark)", textAlign: "center", marginTop: "10px", fontStyle: "italic", fontSize: "13px" }}>Please note that N$50.00 application fee is compulsory and this fee does not guarantee you a stall. It is for application processing</p>
            <div style={{ display: "flex", alignContent: "space-between" }}>
              <Button icon="back" caption="BACK" width="150px" click={() => setStep(1)} />
              <Button icon="apply" caption="CONTINUE" width="150px" click={validateTerms} />
            </div>
          </TabLockPanel>
        )}
        {step === 3 && (
          <TabLockPanel direction="column" margin="20px 30px">
            <h1 style={{ margin: "auto", color: "var(--error)" }}>VERIFICATION CODE:</h1>
            <PinBox ref={pinRef} digits={5} displayCode={true} setPin={setCode} margin="30px 0 0 0" />
            <div style={{ display: "flex", alignContent: "space-between" }}>
              <Button icon="back" caption="BACK" width="150px" click={() => setStep(2)} />
              <Button icon="apply" caption="SUBMIT" width="150px" click={submit} />
            </div>
          </TabLockPanel>
        )}
      </div>
    );
  } else {
    return (
      <div className="main-container">
        <div className="dialog-container" style={{ margin: "auto", width: "450px" }}>
          <div className="dialog-title">
            <p>ONLINE APPLICATION</p>
          </div>
          <Wizard step={step} wizardList={wizardList} margin="30px" message={message} />

          {step === 0 && (
            <TabLockPanel direction="column" margin="20px 30px">
              <TextBox ref={businessNameRef} type="text" icon="shop" label="Business name" text={businessName} setText={setBusinessName} margin="0 0 30px 0" />
              <TextBox ref={contactPersonRef} type="text" icon="person" label="Contact person" text={contactPerson} setText={setContactPerson} margin="0 0 30px 0" />
              <TextBox ref={nationalIdRef} type="number" icon="id" label="ID number" text={nationalId} setText={setNationalId} margin="0 0 30px 0" />
              <TextBox ref={mobileRef} type="tel" icon="mobile" label="Mobile e.g 081xxxxxxx" text={mobile} setText={setMobile} margin="0 0 30px 0" />
              <Button icon="apply" caption="CONTINUE" width="150px" click={validateBusiness} />
            </TabLockPanel>
          )}
          {step === 1 && (
            <TabLockPanel direction="column" margin="20px 30px">
              <RadioButton source={stallList} displayField="description" selectionField="stallId" value={stallId} setValue={setStallId} margin="20px" />
              <TextAreaBox ref={descriptionRef} icon="shop" label="Description, e.g braai meat, cool drink etc" text={description} setText={setDescription} margin="0 0 30px 0" />
              <div style={{ display: "flex", alignContent: "space-between" }}>
                <Button icon="back" caption="BACK" width="150px" click={() => setStep(0)} />
                <Button icon="apply" caption="CONTINUE" width="150px" click={validateStall} />
              </div>
            </TabLockPanel>
          )}
          {step === 2 && (
            <TabLockPanel direction="column" margin="20px 30px">
              <h1 style={{ margin: "0 auto 10px auto", color: "var(--error)" }}>BANKING DETAILS:</h1>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ color: "var(--label)", margin: "10px 20px" }}>Bank:</p>
                <p style={{ margin: "10px 20px", fontWeight: 500, color: "var(--primary)" }}>First National Bank</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ color: "var(--label)", margin: "10px 20px" }}>Account name:</p>
                <p style={{ margin: "10px 20px", fontWeight: 500, color: "var(--primary)" }}>PstMobile</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ color: "var(--label)", margin: "10px 20px" }}>Account number:</p>
                <p style={{ margin: "10px 20px", fontWeight: 500, color: "var(--primary)" }}>64279837884</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ color: "var(--label)", margin: "10px 20px" }}>Reference:</p>
                <p style={{ margin: "10px 20px", fontWeight: 500, color: "var(--primary)", textAlign: "right" }}>USE BUSINESS NAME AS REFERENCE</p>
              </div>
              <CheckBox caption="I accept terms and conditions (Application fee N$50.00)" value={accept} setValue={setAccept} position="right" margin="20px 0" />
              <h1 style={{ textAlign: "center", color: "var(--error)", marginTop: "10px" }}>PLEASE NOTE!!!</h1>
              <p style={{ color: "var(--dark)", textAlign: "center", marginTop: "10px", fontStyle: "italic", fontSize: "13px" }}>Please note that N$50.00 application fee is compulsory and this fee does not guarantee you a stall. It is for application processing</p>
              <div style={{ display: "flex", alignContent: "space-between" }}>
                <Button icon="back" caption="BACK" width="150px" click={() => setStep(1)} />
                <Button icon="apply" caption="CONTINIUE" width="150px" click={validateTerms} />
              </div>
            </TabLockPanel>
          )}
          {step === 3 && (
            <TabLockPanel direction="column" margin="20px 30px">
              <h1 style={{ margin: "auto", color: "var(--error)" }}>VERIFICATION CODE:</h1>
              <PinBox ref={pinRef} digits={5} displayCode={true} setPin={setCode} margin="30px 0 0 0" isMobile={true} />
              <div style={{ display: "flex", alignContent: "space-between" }}>
                <Button icon="back" caption="BACK" width="150px" click={() => setStep(2)} />
                <Button icon="apply" caption="SUBMIT" width="150px" click={submit} />
              </div>
            </TabLockPanel>
          )}
        </div>
      </div>
    );
  }
};

export default ApplicationPage;
