import { createContext, useContext, useState } from "react";

import { Spinner, Dialog } from "../controls";

const MainContext = createContext({
  isBusy: null,
  setIsBusy: null,
});

export function MainProvider({ children }) {
  const [isBusy, setIsBusy] = useState(false);

  const [open, setOpen] = useState(false);
  const [config, setConfig] = useState({});

  const dialog = ({ type, message, actionCallback }) => {
    setOpen(true);
    setConfig({ type, message, actionCallback });
  };

  const reset = () => {
    setOpen(false);
    setConfig({});
  };

  const accept = () => {
    reset();
    config.actionCallback(true);
  };

  const dismiss = () => {
    reset();
    config.actionCallback(false);
  };

  return (
    <MainContext.Provider value={{ isBusy, setIsBusy, dialog }}>
      {isBusy && <Spinner />}
      {open && <Dialog type={config?.type} message={config?.message} accept={accept} dismiss={dismiss} />}
      {children}
    </MainContext.Provider>
  );
}

export function useMain() {
  const context = useContext(MainContext);

  return context;
}

export function useMessage() {
  const { dialog } = useContext(MainContext);

  const messageBox = ({ ...options }) =>
    new Promise((res) => {
      dialog({ actionCallback: res, ...options });
    });

  return { messageBox };
}
