import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import * as pages from "./pages";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<pages.SplashScreen />} />
        <Route path="/home" element={<pages.MasterPage />} />
        <Route path="/application" element={<pages.ApplicationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
