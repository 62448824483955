import { forwardRef, useImperativeHandle, useRef } from "react";

import * as icons from "../../library/icons";

const Button = forwardRef(({ icon, caption, width, margin, click }, ref) => {
  const buttonRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        buttonRef.current.focus();
      });
    },
  }));

  const buttonClick = () => {
    if (click) click();
  };

  return (
    <button ref={buttonRef} className="button" style={{ width: width && width, margin: margin && margin }} onClick={buttonClick}>
      {icon && (
        <svg viewBox="0 0 24 24">
          <path d={icons[icon]}></path>
        </svg>
      )}
      <p>{caption}</p>
    </button>
  );
});

export default Button;
