import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo.png";
import festival from "../../assets/logo.jpg";

import { Button } from "../../controls";

const MasterPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="header">
        <img src={logo} alt="PstMobile logo" />
      </div>
      <div className="master-content">
        <Button icon="apply" caption="ONLINE APPLICATION" click={() => navigate("/application")} />
        <img src={festival} alt="" />
      </div>
    </>
  );
};

export default MasterPage;
