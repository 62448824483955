export const titleCase = (text) => {
  if (!text) {
    return "";
  }
  return text.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

export const generateCode = (length) => {
  let result = "";
  let characters = "0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};
