import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo.png";

const SplashScreen = () => {
  const navigate = useNavigate();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 8000);
    });
  }

  useEffect(() => {
    mounted().then(() => {
      navigate("/home");
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gradient">
      <img src={logo} alt="PstMobile logo" style={{ width: "200px", margin: "0 auto" }} />
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default SplashScreen;
