import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import * as icons from "../../library/icons";

const TextBox = forwardRef(({ icon, text, setText, maxLength, label, pressEnter, margin, type }, ref) => {
  const [hasFocus, setHasFocus] = useState(false);

  const controlBoxRef = useRef();
  const inputRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        setHasFocus(true);
        inputRef.current.focus();
        inputRef.current.select();
      });
    },
  }));

  useEffect(() => {
    function handleClickOutside(event) {
      if (hasFocus && controlBoxRef.current && !controlBoxRef.current.contains(event.target)) {
        setHasFocus(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    if (text) inputRef.current.value = text;
    else inputRef.current.value = "";
  }, [text]);

  const gotFocus = (e) => {
    setHasFocus(true);
    e.target.select();
  };

  const lostFocus = () => {
    setHasFocus(false);
  };

  const valueChange = (e) => {
    if (setText) setText(e.target.value);
  };

  const keyDown = (e) => {
    if (e.key === "Enter" && pressEnter) pressEnter();
    else if (e.key === "Tab") setHasFocus(false);
  };

  return (
    <div ref={controlBoxRef} className="control-box" style={{ border: hasFocus ? "1px solid var(--primary)" : "1px solid var(--border)", margin: margin && margin }}>
      <div className="control-button">
        <svg viewBox="0 0 24 24">
          <path d={icons[icon]}></path>
        </svg>
      </div>
      <input type={type} ref={inputRef} placeholder={label} spellCheck="false" autoComplete="off" autoCorrect="false" maxLength={maxLength} onFocus={gotFocus} onKeyDown={keyDown} onChange={valueChange} onBlur={lostFocus} />
    </div>
  );
});

export default TextBox;
