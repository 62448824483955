import { useRef, useEffect } from "react";

import { TabLockPanel } from "../../controls";
import { confirm, notification, error, warning } from "../../library/icons";

const Dialog = ({ type, message, accept, dismiss }) => {
  const dialogRef = useRef();

  const handleEscape = () => {
    if (type === 98) dismiss();
    else accept();
  };

  const handleEnter = () => {
    accept();
  };

  const keyListenersMap = new Map([
    [27, handleEscape],
    [13, handleEnter],
  ]);

  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }

    document.addEventListener("keydown", keyListener);

    return () => document.removeEventListener("keydown", keyListener);
  });

  useEffect(() => {
    dialogRef.current.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="overlay" style={{ zIndex: 98, backgroundColor: "rgba(0, 0, 0, 5%)" }}>
      <div ref={dialogRef} className="dialog-container" style={{ width: "80%" }} tabIndex="0">
        <div className="dialog-title" style={{ background: "var(--white)", margin: "10px" }}>
          <p style={{ fontSize: "16px", fontWeight: "600", color: type === 1 ? "var(--success)" : type === 98 ? "var(--confirm)" : type === 99 ? "var(--error)" : "var(--warning)" }}>{type === 1 ? "NOTIFICATION" : type === 98 ? "CONFIRMATION" : type === 99 ? "EXCEPTION" : "VALIDATION"}</p>
        </div>
        <TabLockPanel direction="column">
          <div style={{ display: "flex", margin: "0 20px 20px 20px" }}>
            <div className="dialog-icon">
              <svg viewBox="0 0 24 24" style={{ height: "36px", width: "36px", fill: type === 1 ? "var(--success)" : type === 98 ? "var(--confirm)" : type === 99 ? "var(--error)" : "var(--warning)" }}>
                <path d={type === 1 ? notification : type === 98 ? confirm : type === 99 ? error : warning} />
              </svg>
            </div>
            <div className="dialog-message">
              {type === 99 ? (
                <>
                  <p>{message}</p>
                </>
              ) : (
                <p dangerouslySetInnerHTML={{ __html: message }} />
              )}
            </div>
          </div>
          <div className="dialog-button" style={{ borderTop: "1px solid var(--border)", padding: "0" }}>
            <button className="ios-button" onClick={accept}>
              <p style={{ color: "var(--confirm)" }}>{type === 98 ? "YES" : "OK"}</p>
            </button>
            {type === 98 && (
              <button style={{ borderLeft: "1px solid var(--border)", borderRadius: "0 10px 10px 0" }} className="ios-button" onClick={dismiss}>
                <p style={{ color: "var(--confirm)" }}>NO</p>
              </button>
            )}
          </div>
        </TabLockPanel>
      </div>
    </div>
  );
};

export default Dialog;
