import { useEffect, useRef } from "react";

const TabLockPanel = ({ children, margin, direction }) => {
  const panelRef = useRef();

  const handleTabKey = (e) => {
    const focusableElements = panelRef.current.querySelectorAll('input, textarea, button, [tabindex="0"]');

    if (focusableElements && focusableElements.length > 0) {
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        return e.preventDefault();
      }

      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        e.preventDefault();
      }
    }
  };

  const keyListenersMap = new Map([[9, handleTabKey]]);

  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }
    document.addEventListener("keydown", keyListener);

    return () => document.removeEventListener("keydown", keyListener);
  });

  return (
    <div ref={panelRef} style={{ display: "flex", margin: margin && margin, flexDirection: direction && `${direction}`, height: "100%" }}>
      {children}
    </div>
  );
};

export default TabLockPanel;
