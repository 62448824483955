const RadioButton = ({ source, displayField, selectionField, value, setValue, margin }) => {
  const changeItem = (item) => {
    setValue(item[selectionField]);
  };

  return (
    <div className="radio-container" style={{ margin: margin && margin }}>
      {source.map((item) => {
        return (
          <div key={item[selectionField]} className="radio-item" onClick={() => changeItem(item)}>
            <svg viewBox="0 0 24 24" style={{ fill: "var(--primary)", margin: "-2px auto", height: "24px", width: "24px" }}>
              <path d={value === item[selectionField] ? "M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z" : "M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"} />
            </svg>
            <p style={{ color: "var(--label)", fontSize: "14px", margin: "auto 0 auto 15px" }}>{item[displayField]}</p>
          </div>
        );
      })}
    </div>
  );
};

export default RadioButton;
