import { forwardRef, useImperativeHandle, useRef } from "react";

const CheckBox = forwardRef(({ isReadOnly, caption, value, setValue, margin, nullable, position }, ref) => {
  const checkBoxRef = useRef();

  function mounted() {
    return new Promise((res) => {
      setTimeout(res, 10);
    });
  }

  useImperativeHandle(ref, () => ({
    focus() {
      mounted().then(() => {
        checkBoxRef.current.focus();
      });
    },
  }));

  const valueChange = () => {
    if (nullable) {
      if (!isReadOnly && setValue) {
        if (value === null) setValue(true);
        else if (value) setValue(false);
        else setValue(null);
      }
    } else {
      if (!isReadOnly && setValue) {
        if (value === null) setValue(true);
        else setValue(!value);
      }
    }
  };

  const keyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      valueChange();
    }
  };

  return (
    <div className="toggle-container" style={{ margin: margin && margin }}>
      {caption && position === "left" && (
        <p style={{ fontSize: "11px", color: "var(--label)", margin: "auto 10px auto 0" }} onClick={valueChange}>
          {caption}
        </p>
      )}
      <div ref={checkBoxRef} className="check-box" tabIndex={isReadOnly ? "" : "0"} onClick={valueChange} onKeyDown={keyDown}>
        <svg viewBox="0 0 24 24" style={{ fill: value === null ? "var(--label)" : value ? "var(--success)" : "transparent" }}>
          <path d={value === null ? "M20 14H4V10H20V14Z" : value ? "M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" : ""} />
        </svg>
      </div>
      {caption && position === "right" && (
        <p style={{ fontSize: "11px", color: "var(--label)", margin: "auto 0 auto 10px" }} onClick={valueChange}>
          {caption}
        </p>
      )}
    </div>
  );
});

export default CheckBox;
